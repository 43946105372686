<template>
  <div>
    <div class="q-heard">
      <van-icon name="arrow-left" class="van-icon-left"/>
      <van-search
          v-model="value"
          placeholder="请输入搜索关键词"
          :show-action="true"
          @search="onSearch"
          @focus="onFocus"
          left-icon="arrow-down>"
          class="h-flex-auto"
      >
        <div slot="label" @click="onSearch" class="h-flex">
          <van-dropdown-menu active-color="#006BFE">
            <van-dropdown-item v-model="value1" :options="option1" />
          </van-dropdown-menu>
          <div class="jig-saw"></div>
        </div>
        <div slot="action" @click="onSearch" class="h-text-brandColor">
          搜索
        </div>
      </van-search>
    </div>
    <div class="list">
      <div class="list-itme" v-for="(itme,index) in list" :key="index">
        <div class="list-title">
          <div class="list-title-left" :style="gradientStyle(index)">
              {{itme.userName?itme.userName.substring(0,1):''}}
          </div>
          <div class="list-title-right">
            {{itme.userName}} | {{contactPhoneSub(itme.contactPhone)}}
          </div>
        </div>
        <div class="list-line"></div>
        <div class="list-user h-flex justify-content-between">
          <div class="list-user-left">
            <span class="list-user-left">用水户号：</span>
            <span class="list-user-right">{{itme.waterCode || ''}}</span>
          </div>
          <div class="list-user-left">
            <span class="list-user-left">钢 印 号 ：</span>
            <span class="list-user-right">{{itme.steelCode || ''}}</span>
          </div>
        </div>
        <div class="list-user ">
          <div class="list-user-left">
            <span class="list-user-left">用户地址：</span>
            <span class="list-user-right">{{installAddressSub(itme.installAddress)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api";

/**
 * @author xjw
 * @date  2024-07-09 10:46
 * @version 1.0
 */
// 判断是否为微信环境
const isWechat = () => {
  return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
}
export default {
  name: "QueryCodeList",
  components: {},
  data() {
    return {
      tenantCode:'',
      appSaasId:'',
      code:'',
      token:'',
      list:[],
      value: '',
      value1:'userName',
      showAction: false,
      option1: [
        { text: '姓名', value: 'userName' },
        { text: '手机号码', value: 'contactPhone' },
        { text: '钢印号', value: 'steelCode' }
      ],
    }
  },
  computed: {
  },
  created() {
   /* let params = this.$route.query;
    let tenantCode = params.tenantCode
    if (tenantCode || tenantCode == 0){
      this.tenantCode =tenantCode
      localStorage.setItem("tenantCode",this.tenantCode)
    }
    let appSaasId = params.appId
    if (appSaasId){
      this.appSaasId =appSaasId
      localStorage.setItem("appSaasId",this.appSaasId)
    }
    this.getWxCode();
    if(this.code != ''){
      this.getToken();
    }*/
    this.onSearch()
  },
  methods: {
    installAddressSub(installAddress){
      if (installAddress){
       let xq = installAddress.indexOf('小区')
       let d = installAddress.indexOf('栋')
       let dy = installAddress.indexOf('单元')
        let srt=''
        if (xq !=-1){
         let xq1 =Math.floor(xq * 0.4 )
          if (xq1 > 3){
            srt=installAddress.substring(0,xq-xq1)+'**小区'
          }else {
            srt=installAddress.substring(0,xq-3)+'**小区'
          }
          if (d !=-1){
            srt= srt+'**栋'
          }
          if (dy !=-1){
            srt =srt+'**单元**'
          }
          return srt
        }else {
          return  installAddress.substring(0,1)+installAddress.substring(1,Math.floor(installAddress.length * 0.6)) +'*****'
        }
      }else {
        return ''
      }
    },
    contactPhoneSub(phone){
      if (phone){
        if (phone.length > 7 ){
          return  phone.substring(0,3)+'****'+phone.substring(phone.length-4)
        }else if (phone.length > 3 && phone.length <= 7){
          return  phone.substring(0,3)+'****'+phone.substring(phone.length-3)
        }else {
          return phone +'**'
        }
      }else {
        return ''
      }
    },
    gradientStyle(index) {
      const darkColor1 = `rgb(${Math.floor(Math.random() * 160) + 64},${Math.floor(Math.random() * 160) + 64},${Math.floor(Math.random() * 160) + 64})`;
      return {
        background: darkColor1
      };
    },
    getWxCode() {
      if (isWechat) {
        let code = this.getUrlCode().code; //是否存在code
        let tenantCode = this.getUrlCode().tenantCode
        if (tenantCode || tenantCode == 0){
          this.tenantCode =tenantCode
          localStorage.setItem("tenantCode",this.tenantCode)
        }
        let appSaasId = this.getUrlCode().appId
        if (appSaasId){
          this.appSaasId =appSaasId
          localStorage.setItem("appSaasId",this.appSaasId)
        }
        let local = window.location.href;
        //local =  local.replace('http://lp.saas.jincaiyun.cn:9999','https://lp.test.jcrjsw.com')
        if (code == null || code === "") {
          let appIds = this.appSaasId ? this.appSaasId : localStorage.getItem("appSaasId")
          //不存在就打开上面的地址进行授权
          window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              appIds +
              "&redirect_uri=" +
              encodeURIComponent(local) +
              "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        } else {
          this.code = code;
        }
        //this.code = code;
      }
    },
    getToken(){
      request.get('/pay/waterFee/token',{params:{code:this.code,tenantCode:this.tenantCode?this.tenantCode:localStorage.getItem("tenantCode"),orgId:''}}).then(res=>{
        this.token=res.data
        localStorage.setItem("token",this.token)
        this.onSearch()
      }).catch(err=>{
        alert(err.message)
      })
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    onSearch() {
      let value1 = this.value1
      let data = {
        token:'668fae48c39b37c26864a1d4',
      }
      data[value1]=this.value
      //request.get('/pay/waterFee/queryCodeList',{params:{userName:'谢远春',token:this.token?this.token:localStorage.getItem("token")}}).then(res=>{
      request.get('/pay/waterFee/queryCodeList',{params:data}).then(res=>{
        this.list=res.data
      })
    },
    onFocus() {
      this.showAction = true;
    },
    onCancel() {
      this.showAction = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 12px;
  .list-itme {
    height: 141px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EEEEEE;
    padding: 16px;
    margin-bottom: 12px;
    .list-line {
      height: 1px;
      background: #EEEEEE;
      margin-top: 12px;
    }
    .list-user {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding-right: 14px;
      margin-top: 12px;
      text-align: left;
      &-left {
        color: #666666;
      }
      &-right {
        color: #333333;
      }
    }
    .list-title {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      .list-title-left {
        width: 24px;
        height: 24px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 50%;
      }
      .list-title-right {
        margin-left: 8px;
        color: #333333;
      }
    }
  }
}
.jig-saw {
  margin-left: 8px;
  height: 24px;
  width: 8px;
  border-right: solid 1px #EEEEEE;
  margin-top: 6px;
}
.van-icon-left {
  margin-top: 12px;
  width: 20px;
  height: 20px;
  padding: 4px;
}
.q-heard {
  display: flex;
  height: 44px;
  padding: 0 12px;
  background-color: #FFFFFF;
}
::v-deep {
  .van-search__content {
    padding-left:4px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
  }
  .van-dropdown-menu__bar {
    height: 34px;
    background: none;
    box-shadow: none;
  }
  .van-cell__title, .van-cell__value {
    margin-left: 10px;
  }
}
</style>
